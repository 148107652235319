import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Section, PostGrid, FeaturedGrid, CategoryButtons, Link, SearchBarInput } from 'components';
import * as styles from './resource_category.module.scss';

const Category = ({ location, data }) => {
  const {
    category: { data: categoryData },
    categoryPosts: { nodes: posts = {} },
    categoryList: { nodes: categories = {} },
    prismicResourceLandingPage: {
      data: { category_grid_theme: categoryGridTheme },
    },
    blogSearchIndexData,
  } = data;

  const {
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    category_name: categoryName,
    category_description: categoryDesc,
  } = categoryData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
  };

  const recentPosts = posts?.slice(0, 3);
  const remainingPosts = posts?.slice(3);

  return (
    <>
      <Layout location={location} seo={seo} theme="light">
        <Section className={styles.resourceHeader}>
          {categoryName?.text && (
            <div dangerouslySetInnerHTML={{ __html: categoryName.html }} className={styles.headerTitle} />
          )}
          {categoryDesc?.text && (
            <div dangerouslySetInnerHTML={{ __html: categoryDesc.html }} className={styles.headerContent} />
          )}
          <SearchBarInput blogPosts={blogSearchIndexData} />
        </Section>
        {recentPosts?.length > 0 ? (
          <Section className={styles.gridContainer} containerClassName={styles.container} largeContainer>
            <FeaturedGrid posts={recentPosts} className={styles.featuredGrid} />
          </Section>
        ) : (
          <Section className={styles.noPostsFound} containerClassName={styles.container} largeContainer>
            No posts found - <Link to="/resources/">Back to resources</Link>
          </Section>
        )}
        {remainingPosts?.length > 0 && (
          <Section className={styles.postContainer} largeContainer>
            <PostGrid posts={remainingPosts} vertical showExcerpt readMore />
          </Section>
        )}
        <CategoryButtons categories={categories} categoryGridTheme={categoryGridTheme} />
      </Layout>
    </>
  );
};

export default Category;

export const categoryQuery = graphql`
  query CategoryBySlug($uid: String!, $lang: String!) {
    category: prismicResourceCategory(uid: { eq: $uid }, lang: { eq: $lang }) {
      id
      uid
      prismicId
      dataRaw
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        category_name {
          html
          text
        }
        category_description {
          html
          text
        }
      }
    }
    categoryPosts: allPrismicResourcePost(
      filter: { data: { category: { uid: { eq: $uid }, lang: { eq: $lang } } } }
      sort: { order: DESC, fields: data___date }
    ) {
      nodes {
        uid
        data {
          alternative_link {
            url
          }
          author {
            url
            document {
              ... on PrismicResourceAuthor {
                id
                data {
                  author_name {
                    text
                    html
                  }
                }
              }
            }
          }
          category {
            document {
              ... on PrismicResourceCategory {
                id
                data {
                  category_name {
                    html
                    text
                  }
                }
                uid
              }
            }
          }
          featured_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: CONSTRAINED, placeholder: BLURRED, aspectRatio: 1.7)
              }
            }
          }
          title {
            html
            text
          }
          excerpt {
            html
            text
          }
          date(formatString: "MMMM d, YYYY")
        }
      }
    }
    categoryList: allPrismicResourceCategory(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          category_name {
            html
            text
          }
        }
        uid
      }
    }
    prismicResourceLandingPage(lang: { eq: $lang }) {
      data {
        category_grid_theme
      }
    }
    blogSearchIndexData: allPrismicResourcePost(filter: { lang: { eq: $lang } }) {
      nodes {
        ...BlogSearchIndexFragment
      }
    }
  }
`;
